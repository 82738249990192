<template>
  <div class="d-flex justify-content-start align-items-center m-4">
    <div class="form-check form-switch form-check-right">
      <input
        class="form-check-input"
        type="checkbox"
        role="switch"
        id="flexSwitchCheckRightDisabled"
        :checked="settingValue"
        @change="changeSettings"
      />
      <h6
        class="card-title mb-0 form-check-label"
        for="flexSwitchCheckRightDisabled"
      >
        {{ settingName }}
      </h6>
    </div>
  </div>
</template>

<script>
export default {
  name: "switchSetting",
  props: {
    settingName: {
      type: String,
      required: true,
    },
    settingValue: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    async changeSettings() {
      const setting = {
        name: this.settingName,
        value: !this.settingValue,
      };
      await this.$store.dispatch("editSetting", setting);
      await this.$store.dispatch("getSettings");
    },
  },
};
</script>

<style scoped></style>
