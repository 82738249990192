<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header">
        <div class="d-flex">
          <div
            v-if="host && host.includes('starnovelty')"
            class="navbar-brand-box horizontal-logo"
          >
            <router-link to="/" class="logo logo-light">
              <span class="logo-sm">
                <img src="/images/logo-sm.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img src="/images/logo-light.png" alt="" height="50" />
              </span>
            </router-link>
          </div>

          <button
            type="button"
            class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
            id="topnav-hamburger-icon"
            @click="toggleMobileNav"
          >
            <span class="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>

          <!-- App Search-->
          <form class="app-search d-none d-md-block me-2">
            <div class="position-relative">
              <input
                type="text"
                class="form-control"
                placeholder="Search..."
                autocomplete="off"
                id="search-options"
                value=""
              />
              <span class="las la-search search-widget-icon"></span>
              <span
                class="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                id="search-close-options"
              ></span>
            </div>
            <div class="dropdown-menu dropdown-menu-lg" id="search-dropdown">
              <div data-simplebar style="max-height: 320px">
                <!-- item-->
                <div class="dropdown-header">
                  <h6 class="text-overflow text-muted mb-0 text-uppercase">
                    Recent Searches
                  </h6>
                </div>

                <div class="dropdown-item bg-transparent text-wrap">
                  <a
                    href="index.html"
                    class="btn btn-soft-secondary btn-sm btn-rounded"
                    >how to setup <i class="mdi mdi-magnify ms-1"></i
                  ></a>
                  <a
                    href="index.html"
                    class="btn btn-soft-secondary btn-sm btn-rounded"
                    >buttons <i class="mdi mdi-magnify ms-1"></i
                  ></a>
                </div>
                <!-- item-->
                <div class="dropdown-header mt-2">
                  <h6 class="text-overflow text-muted mb-1 text-uppercase">
                    Pages
                  </h6>
                </div>

                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item notify-item">
                  <i
                    class="ri-bubble-chart-line align-middle fs-18 text-muted me-2"
                  ></i>
                  <span>Analytics Dashboard</span>
                </a>

                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item notify-item">
                  <i
                    class="ri-lifebuoy-line align-middle fs-18 text-muted me-2"
                  ></i>
                  <span>Help Center</span>
                </a>

                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item notify-item">
                  <i
                    class="ri-user-settings-line align-middle fs-18 text-muted me-2"
                  ></i>
                  <span>My account settings</span>
                </a>

                <!-- item-->
                <div class="dropdown-header mt-2">
                  <h6 class="text-overflow text-muted mb-2 text-uppercase">
                    Members
                  </h6>
                </div>

                <div class="notification-list">
                  <!-- item -->
                  <a
                    href="javascript:void(0);"
                    class="dropdown-item notify-item py-2"
                  >
                    <div class="d-flex">
                      <img
                        src="/images/users/avatar-2.jpg"
                        class="me-3 rounded-circle avatar-xs"
                        alt="user-pic"
                      />
                      <div class="flex-1">
                        <h6 class="m-0">Angela Bernier</h6>
                        <span class="fs-11 mb-0 text-muted">Manager</span>
                      </div>
                    </div>
                  </a>
                  <!-- item -->
                  <a
                    href="javascript:void(0);"
                    class="dropdown-item notify-item py-2"
                  >
                    <div class="d-flex">
                      <img
                        src="/images/users/avatar-3.jpg"
                        class="me-3 rounded-circle avatar-xs"
                        alt="user-pic"
                      />
                      <div class="flex-1">
                        <h6 class="m-0">David Grasso</h6>
                        <span class="fs-11 mb-0 text-muted">Web Designer</span>
                      </div>
                    </div>
                  </a>
                  <!-- item -->
                  <a
                    href="javascript:void(0);"
                    class="dropdown-item notify-item py-2"
                  >
                    <div class="d-flex">
                      <img
                        src="/images/users/avatar-5.jpg"
                        class="me-3 rounded-circle avatar-xs"
                        alt="user-pic"
                      />
                      <div class="flex-1">
                        <h6 class="m-0">Mike Bunch</h6>
                        <span class="fs-11 mb-0 text-muted"
                          >React Developer</span
                        >
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div class="text-center pt-3 pb-1">
                <a
                  href="pages-search-results.html"
                  class="btn btn-primary btn-sm"
                  >View All Results <i class="ri-arrow-right-line ms-1"></i
                ></a>
              </div>
            </div>
          </form>
        </div>

        <div class="d-flex align-items-center">
          <div class="dropdown d-md-none topbar-head-dropdown header-item">
            <button
              type="button"
              class="btn btn-icon btn-topbar btn-ghost-primary rounded-circle"
              id="page-header-search-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="bx bx-search fs-22"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              aria-labelledby="page-header-search-dropdown"
            >
              <form class="p-3">
                <div class="form-group m-0">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search ..."
                      aria-label="Recipient's username"
                    />
                    <button class="btn btn-primary" type="submit">
                      <i class="mdi mdi-magnify"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div
            class="dropdown header-item"
            @click="showDropdown = !showDropdown"
          >
            <button
              type="button"
              class="btn"
              id="page-header-user-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="d-flex align-items-center">
                <img
                  v-if="user && user.type === 'admin'"
                  class="header-profile-user"
                  src="/images/users/admin.png"
                  alt="Admin Header Avatar"
                />
                <img
                  v-else
                  class="header-profile-user"
                  src="/images/users/user.png"
                  alt="User Header Avatar"
                />
                <span class="text-start ms-xl-2">
                  <span
                    class="d-none d-xl-inline-block fw-medium user-name-text fs-16"
                    >{{ user.username }}
                    <i class="las la-angle-down fs-12 ms-1"></i
                  ></span>
                </span>
              </span>
            </button>
            <div
              class="dropdown-menu dropdown-menu-end"
              :class="{ show: showDropdown }"
            >
              <!-- item-->
              <!--              <router-link to="/" class="dropdown-item">-->
              <!--                <i class="bx bx-user fs-15 align-middle me-1"></i>-->
              <!--                <span key="t-profile">Profile</span>-->
              <!--              </router-link>-->
              <!--              <router-link to="/" class="dropdown-item">-->
              <!--                <i class="bx bx-wallet fs-15 align-middle me-1"></i>-->
              <!--                <span key="t-my-wallet">My Wallet</span>-->
              <!--              </router-link>-->
              <router-link
                v-if="user.username === 'adminjoe'"
                to="/settings"
                class="dropdown-item d-block"
              >
                <i class="bx bx-wrench fs-15 align-middle me-1"></i>
                <span key="t-settings">Settings</span>
              </router-link>
              <!--              <router-link to="/" class="dropdown-item">-->
              <!--                <i class="bx bx-lock-open fs-15 align-middle me-1"></i>-->
              <!--                <span key="t-lock-screen">Lock screen</span>-->
              <!--              </router-link>-->
              <div
                class="dropdown-divider"
                v-if="user.username === 'adminjoe'"
              ></div>
              <button class="dropdown-item text-danger" @click="signOut">
                <i
                  class="bx bx-power-off fs-15 align-middle me-1 text-danger"
                ></i>
                <span key="t-logout">Logout</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <!-- removeNotificationModal -->
  <div
    id="removeNotificationModal"
    class="modal fade zoomIn"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="NotificationModalbtn-close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style="width: 100px; height: 100px"
            ></lord-icon>
            <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you sure ?</h4>
              <p class="text-muted mx-4 mb-0">
                Are you sure you want to remove this Notification ?
              </p>
            </div>
          </div>
          <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              class="btn w-sm btn-light"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn w-sm btn-danger"
              id="delete-notification"
            >
              Yes, Delete It!
            </button>
          </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</template>

<script>
export default {
  name: "TopbarComponent",
  data() {
    return {
      showDropdown: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.StateUser ? this.$store.getters.StateUser : "";
    },
    host() {
      return window.location.hostname || "";
    },
  },
  methods: {
    toggleMobileNav() {
      this.eventBus.emit("toggle-mobile-nav");
    },
    async signOut() {
      await this.$store.dispatch("SignOut");
      this.$router.push("/signin");
    },
  },
};
</script>

<style scoped>
.dropdown-menu {
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate(0px, 64px);
}
</style>
