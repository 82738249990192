<template>
  <dialogue-modal
    v-if="buyer"
    :title="dialogueMessage"
    :message="buyer.name"
    :close-popup="hideModal"
    :buttons="buttons"
  />
  <page-title pageTitle="Customers" title="Recover Customers" />

  <div class="row pb-4 gy-3">
    <div class="col-sm-4">
      <router-link to="/buyers" class="btn btn-primary addtax-modal">
        <i class="las la-arrow-left me-1"></i> Back
      </router-link>
    </div>

    <div class="col-sm-auto ms-auto">
      <div class="d-flex gap-3">
        <div class="search-box">
          <input
            type="text"
            class="form-control"
            id="searchMemberList"
            placeholder="Search for Result"
            v-model="searchQuery"
            @input="goToPage(1)"
          />
          <i class="las la-search search-icon"></i>
        </div>
      </div>
    </div>

    <div class="col-sm-auto">
      <div class="d-flex input-group">
        <label class="input-group-text" for="inputGroupSelect01">Show</label>
        <select
          class="form-select"
          id="resultsPerPage"
          v-model="resultsPerPage"
          @change="goToPage(1)"
        >
          <option
            v-for="option in resultsPerPageOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div v-if="data.length > 0">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <table-component
              :headers="headers"
              :data="data"
              :actions="actions"
              :offset="(currentPage - 1) * resultsPerPage"
              @sort="goToPage"
            />
          </div>
        </div>
      </div>
    </div>
    <pagination-component
      :rows-loaded="data.length"
      :current-page="currentPage"
      :num-pages="numPages"
      :total-rows="totalRows"
      :go-to-page="goToPage"
    />
  </div>

  <div v-else class="row">
    <div class="col-xl-12">
      <div class="card">
        <LoadingModal v-if="loading" />
        <div v-else class="card-body">
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import TableComponent from "@/components/utils/Table";
import LoadingModalComponent from "@/components/utils/LoadingModal";
import PaginationComponent from "@/components/utils/paginationComponent";
import DialogueModal from "@/components/utils/modals/DialogueModal";

export default {
  name: "DeletedBuyersView",
  components: {
    DialogueModal,
    PaginationComponent,
    PageTitle: PageTitleComponent,
    TableComponent: TableComponent,
    LoadingModal: LoadingModalComponent,
  },
  data() {
    return {
      searchQuery: "",
      resultsPerPage: this.$store.getters.settings["Results Per Page"],
      dialogueMessage: "",
      dialogueFunction: null,
      buyer: null,
    };
  },
  computed: {
    headers() {
      return this.$store.getters.settings["Buyer Headers"];
    },
    data() {
      return this.$store.getters.allBuyers;
    },
    currentPage() {
      return this.$store.getters.buyersCurrentPage;
    },
    numPages() {
      return this.$store.getters.buyersNumPages;
    },
    totalRows() {
      return this.$store.getters.buyersTotalRows;
    },
    resultsPerPageOptions() {
      return this.$store.getters.settings["Results Per Page Options"];
    },
    actions() {
      return {
        Recover: (buyer) => this.showRecoverModal(buyer),
        Delete: (buyer) => this.showDeleteModal(buyer),
      };
    },
    error() {
      return this.$store.getters.buyersError;
    },
    loading() {
      return this.$store.getters.buyersLoading;
    },
    buttons() {
      return {
        Yes: {
          class: "btn-danger",
          function: this.dialogueFunction,
        },
        No: {
          class: "btn-light px-3",
          function: () => this.hideModal(),
        },
      };
    },
  },
  methods: {
    async goToPage(page, sortBy = null) {
      let filter = {
        sortingList: sortBy,
        currentPage: page,
        resultsPerPage:
          this.resultsPerPage === "ALL" ? this.totalRows : this.resultsPerPage,
        searchQuery: this.searchQuery,
      };
      await this.$store.dispatch("getDeletedBuyers", filter);
    },
    viewBuyer(id) {
      this.$router.push("/buyers/" + id);
    },
    editBuyer(id) {
      this.$router.push("/buyers/edit/" + id);
    },
    showDeleteModal(buyer) {
      this.dialogueMessage = "Are you sure you want to permanently delete?";
      this.dialogueFunction = () => this.deleteBuyer();
      this.buyer = buyer;
    },
    async deleteBuyer() {
      if (this.buyer) {
        await this.$store.dispatch("deleteBuyer", this.buyer.id);
        await this.goToPage(1);
        this.hideModal();
      } else {
        alert("There has been an error");
      }
    },
    showRecoverModal(buyer) {
      this.dialogueMessage = "Are you sure you want to Recover?";
      this.dialogueFunction = () => this.recoverBuyer();
      this.buyer = buyer;
    },
    async recoverBuyer() {
      if (this.buyer) {
        await this.$store.dispatch("recoverBuyer", this.buyer.id);
        await this.goToPage(1);
        this.hideModal();
      } else {
        alert("There has been an error");
      }
    },
    hideModal() {
      this.buyer = null;
      this.dialogueMessage = "";
      this.dialogueFunction = null;
    },
  },
  async created() {
    let filter = {
      resultsPerPage: this.$store.getters.settings["Results Per Page"],
    };
    await this.$store.dispatch("getDeletedBuyers", filter);
  },
  async beforeUnmount() {
    await this.$store.dispatch("resetCustomer");
  },
};
</script>

<style scoped></style>
