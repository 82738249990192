<template>
  <dialogue-modal
    v-if="expenseToDelete"
    title="Are you sure you want to delete?"
    :message="expenseToDelete.reference"
    :close-popup="hideDeleteModal"
    :buttons="buttons"
  />
  <page-title pageTitle="Expenses" title="All Expenses" />

  <div class="row pb-4 gy-3">
    <div class="col-sm-4">
      <router-link to="/expenses/new" class="btn btn-primary addtax-modal"
        ><i class="las la-plus me-1"></i> Add New</router-link
      >
    </div>

    <div class="col-sm-auto ms-auto">
      <div class="d-flex gap-3">
        <div class="search-box">
          <input
            type="text"
            class="form-control"
            id="searchMemberList"
            placeholder="Search for Result"
            v-model="searchQuery"
            @input="goToPage(1)"
          />
          <i class="las la-search search-icon"></i>
        </div>
      </div>
    </div>

    <div class="col-sm-auto">
      <div class="d-flex input-group">
        <label class="input-group-text" for="inputGroupSelect01">Show</label>
        <select
          class="form-select"
          id="resultsPerPage"
          v-model="resultsPerPage"
          @change="goToPage(1)"
        >
          <option
            v-for="option in resultsPerPageOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div v-if="data && data.length > 0">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <table-component
              :headers="headers"
              :footer="footer"
              :data="formattedData"
              :actions="actions"
              :offset="(currentPage - 1) * resultsPerPage"
              @sort="goToPage"
            />
          </div>
        </div>
      </div>
    </div>
    <pagination-component
      :rows-loaded="data.length"
      :current-page="currentPage"
      :num-pages="numPages"
      :total-rows="totalRows"
      :go-to-page="goToPage"
    />
  </div>

  <div v-else class="row">
    <div class="col-xl-12">
      <div class="card">
        <LoadingModal v-if="loading" />
        <div v-else class="card-body">
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import TableComponent from "@/components/utils/Table";
import LoadingModalComponent from "@/components/utils/LoadingModal";
import PaginationComponent from "@/components/utils/paginationComponent";
import DialogueModal from "@/components/utils/modals/DialogueModal";

export default {
  name: "AllExpensesView",
  components: {
    DialogueModal,
    PaginationComponent,
    LoadingModal: LoadingModalComponent,
    PageTitle: PageTitleComponent,
    TableComponent: TableComponent,
  },
  data() {
    return {
      searchQuery: "",
      resultsPerPage: this.$store.getters.settings["Results Per Page"],
      expenseToDelete: null,
    };
  },
  computed: {
    headers() {
      return this.$store.getters.settings["Expense Headers"];
    },
    footer() {
      return ["", "", "", "", "Total", this.expenseTotal, "", "", "", ""];
    },
    data() {
      return this.$store.getters.allExpenses;
    },
    formattedData() {
      const allExpenses = this.$store.getters.allExpenses;
      return allExpenses.map((expense) => ({
        ...expense,
        date: this.dateFormat(expense.date),
      }));
    },
    expenseTotal() {
      if (!this.data || this.data.length === 0) {
        return 0;
      }
      return this.data
        .reduce((total, item) => total + parseFloat(item.amount), 0)
        .toFixed(2);
    },
    currentPage() {
      return this.$store.getters.expensesCurrentPage;
    },
    numPages() {
      return this.$store.getters.expensesNumPages;
    },
    totalRows() {
      return this.$store.getters.expensesTotalRows;
    },
    resultsPerPageOptions() {
      return this.$store.getters.settings["Results Per Page Options"];
    },
    actions() {
      return {
        View: (expense) => this.viewExpense(expense.id),
        Edit: (expense) => this.editExpense(expense.id),
        Delete: (expense) => this.showDeleteModal(expense),
      };
    },
    error() {
      return this.$store.getters.expensesError;
    },
    loading() {
      return this.$store.getters.expensesLoading;
    },
    buttons() {
      return {
        Yes: {
          class: "btn-danger",
          function: () => this.deleteExpense(),
        },
        No: {
          class: "btn-light px-3",
          function: () => this.hideDeleteModal(),
        },
      };
    },
  },
  methods: {
    async goToPage(page, sortBy = null) {
      let filter = {
        sortingList: sortBy,
        currentPage: page,
        resultsPerPage:
          this.resultsPerPage === "ALL" ? this.totalRows : this.resultsPerPage,
        searchQuery: this.searchQuery,
      };
      await this.$store.dispatch("getExpenses", filter);
    },
    dateFormat(date) {
      let parts = date.split("-");
      let formattedDate = parts[1] + "-" + parts[2] + "-" + parts[0];
      return formattedDate;
    },
    viewExpense(id) {
      this.$router.push("/expenses/" + id);
    },
    editExpense(id) {
      this.$router.push("/expenses/edit/" + id);
    },
    showDeleteModal(expense) {
      this.expenseToDelete = expense;
    },
    async deleteExpense() {
      if (this.expenseToDelete) {
        await this.$store.dispatch("deleteExpense", this.expenseToDelete.id);
        await this.goToPage(1);
        this.hideDeleteModal();
      } else {
        alert("There has been an error");
      }
    },
    hideDeleteModal() {
      this.expenseToDelete = null;
    },
  },
  async created() {
    let filter = {
      resultsPerPage: this.$store.getters.settings["Results Per Page"],
    };
    await this.$store.dispatch("getExpenses", filter);
  },
  async beforeUnmount() {
    await this.$store.dispatch("resetExpenses");
  },
};
</script>

<style scoped></style>
