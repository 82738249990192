<template>
  <dialogue-modal
    v-if="invoiceItemToDelete"
    title="Are you sure you want to delete?"
    :message="invoiceItemToDelete.productName"
    :close-popup="hideDeleteModal"
    :buttons="buttons"
  />
  <EditItemPriceModal
    v-if="showEditItemPriceModal"
    :productHistory="productHistory"
    :close-popup="hidePopup"
  />
  <div
    class="row pb-4 mb-4 d-flex justify-content-center border-bottom border-bottom-dashed"
  >
    <div class="col-sm-auto col-md-8">
      <AutoCompleteInput
        type="text"
        placeholder="Search for Product"
        :chosen="productToAdd.value"
        :options="filters"
        :key="reload"
        :autofocus="true"
        icon="las la-barcode search-icon"
        @choice="setProductID"
        class="w-100"
      />
    </div>
  </div>
  <div class="table-responsive overflow-auto" bis_skin_checked="1">
    <table class="invoice-table table table-borderless table-nowrap mb-0">
      <thead class="align-middle">
        <tr class="table-active">
          <th scope="col">No</th>
          <th scope="col" v-for="(header, key) in headers" :key="key">
            {{ header }}
          </th>
          <th v-if="invoiceDetails.showDamagedGoods">Damaged Good</th>
          <th scope="col" style="width: 12%">Action</th>
        </tr>
      </thead>
      <tbody class="align-middle">
        <tr
          class="product"
          v-for="(row, index) in data.sort((a, b) => b.id - a.id)"
          :key="index"
        >
          <td style="text-align: center; width: 30px; vertical-align: middle">
            {{ index + 1 }}
          </td>
          <td>
            {{ row.productName }}
            <button
              type="button"
              class="btn btn-ghost-"
              @click="showPopup(row.id)"
            >
              <i class="las la-edit" />
            </button>
          </td>
          <td>
            <input
              type="text"
              class="form-control-plaintext"
              v-model="row.barcode"
              readonly
            />
          </td>
          <td>
            <input
              class="form-control-plaintext product-price"
              :value="priceFormat(row.sellingPrice)"
              readonly
            />
          </td>
          <td>
            <div class="input-step" bis_skin_checked="1">
              <button
                type="button"
                class="minus"
                @click="
                  row.quantity--;
                  updateInvoiceItem(row);
                "
              >
                –
              </button>
              <input
                type="number"
                class="product-quantity"
                v-model="row.quantity"
                onclick="this.select();"
                @blur="updateInvoiceItem(row)"
              />
              <button
                type="button"
                class="plus"
                @click="
                  row.quantity++;
                  updateInvoiceItem(row);
                "
              >
                +
              </button>
            </div>
          </td>
          <td class="text-end">
            <div bis_skin_checked="1">
              <input
                class="form-control-plaintext product-price"
                :value="priceFormat(row.subtotal)"
                readonly
              />
            </div>
          </td>
          <td v-if="invoiceDetails.showDamagedGoods">
            <input
              class="form-control-plaintext product-price"
              type="checkbox"
              :checked="row.damagedGood == 1 ? true : false"
              @click="
                row.damagedGood = !row.damagedGood;
                updateInvoiceItem(row);
              "
              :disabled="row.quantity >= 0"
            />
          </td>
          <td>
            <button
              type="button"
              @click="showDeleteModal(row)"
              class="btn btn-danger btn-icon"
            >
              <i class="ri-delete-bin-5-line"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <tbody>
        <tr id="newForm" style="display: none">
          <td class="d-none" colspan="5"><p>Add New Form</p></td>
        </tr>
        <tr class="border-top border-top-dashed mt-2">
          <td colspan="3"></td>
          <td colspan="3" class="p-0">
            <table
              v-if="invoiceDetails.status !== 'NEW'"
              class="table table-borderless table-sm table-nowrap align-middle mb-0"
            >
              <tbody>
                <tr
                  class="border-top border-top-dashed"
                  v-for="(value, key, index) in invoiceDetailsFooter"
                  :key="index"
                >
                  <td colspan="5" style="text-align: right; font-weight: bold">
                    {{ key }}
                  </td>
                  <td style="text-align: right">
                    {{ value }}
                  </td>
                  <template
                    v-if="index < Object.keys(suggestedAmountFooter).length"
                  >
                    <td
                      colspan="2"
                      style="text-align: right; font-weight: bold"
                    >
                      {{ Object.keys(suggestedAmountFooter)[index] }}
                    </td>
                    <td style="text-align: right">
                      {{
                        suggestedAmountFooter[
                          Object.keys(suggestedAmountFooter)[index]
                        ]
                      }}
                    </td>
                  </template>
                  <template v-else>
                    <td
                      colspan="4"
                      style="text-align: right; font-weight: bold"
                    ></td>
                  </template>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import AutoCompleteInput from "@/components/utils/AutoCompleteInput";
import EditItemPriceModal from "@/components/invoice/EditItemPriceModal";
import DialogueModal from "@/components/utils/modals/DialogueModal";

export default {
  name: "InvoiceItemsTable",
  components: { DialogueModal, EditItemPriceModal, AutoCompleteInput },
  data() {
    return {
      reload: 0,
      productToAdd: {},
      showEditItemPriceModal: false,
      invoiceItemToDelete: null,
    };
  },
  methods: {
    async addInvoiceItem() {
      const invoice = {
        invoiceID: this.invoiceDetails.id,
        customerID: this.invoiceDetails.customerID,
        productID: this.productToAdd.productID,
      };
      await this.$store.dispatch("addInvoiceItem", invoice);
      this.productToAdd = {};
      this.reload++;
      await this.$store.dispatch("getInvoice", this.invoiceDetails.id);
      await this.$store.dispatch("getInvoiceItems", {
        id: this.invoiceDetails.id,
        filter: null,
      });
    },
    async updateInvoiceItem(row) {
      const invoiceItem = {
        id: row.id,
        invoiceID: row.invoiceID,
        quantity: row.quantity,
        damagedGood: row.damagedGood,
      };
      await this.$store.dispatch("editInvoiceItem", invoiceItem);
      await this.$store.dispatch("getInvoice", this.invoiceDetails.id);
      await this.$store.dispatch("getInvoiceItems", {
        id: this.invoiceDetails.id,
        filter: null,
      });
    },
    showDeleteModal(invoiceItem) {
      this.invoiceItemToDelete = invoiceItem;
    },
    async deleteInvoiceItem() {
      if (this.invoiceItemToDelete) {
        await this.$store.dispatch(
          "deleteInvoiceItem",
          this.invoiceItemToDelete.id
        );
        await this.$store.dispatch("getInvoice", this.invoiceDetails.id);
        await this.$store.dispatch("getInvoiceItems", {
          id: this.invoiceDetails.id,
          filter: null,
        });
        this.hideDeleteModal();
      } else {
        alert("There has been an error");
      }
    },
    hideDeleteModal() {
      this.invoiceItemToDelete = null;
    },
    async setProductID(value, id) {
      let filter = {
        resultsPerPage: this.$store.getters.settings["Results Per Page"],
        searchQuery: value,
      };
      await this.$store.dispatch("getFilters", filter);
      if (
        this.filters.length === 1 &&
        (this.filters[0].barcode === value ||
          this.filters[0].barcode === "0" + value ||
          this.filters[0].boxBarcode === value ||
          this.filters[0].boxBarcode === "0" + value ||
          this.filters[0].name === value)
      ) {
        this.productToAdd.value = "";
        this.productToAdd.productID = this.filters[0].id;
        await this.addInvoiceItem();
      } else {
        this.productToAdd.value = value;
        this.productToAdd.productID = id;
      }
    },
    async showPopup(id) {
      await this.$store.dispatch("getProductHistory", id);
      this.showEditItemPriceModal = true;
    },
    hidePopup() {
      this.showEditItemPriceModal = false;
    },
    priceFormat(price) {
      return `$${price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`.replace("$-", "-$");
    },
  },
  computed: {
    headers() {
      return this.$store.getters.settings["Invoice Item Headers"];
    },
    data() {
      return this.$store.getters.invoice;
    },
    invoiceDetails() {
      return this.$store.getters.invoiceSummary;
    },
    invoiceTotals() {
      return this.$store.getters.invoiceTotals;
    },
    invoiceDetailsFooter() {
      let footer = {
        "Total Amount": this.priceFormat(this.invoiceTotals.total),
        "Total Credit": this.priceFormat(this.invoiceTotals.amountCredited),
        "Redeemed Discount Value": this.priceFormat(
          this.invoiceTotals.discountRedeemed
        ),
        "Redeemed Points Value": "$0.00",
        "Total Paid": this.priceFormat(this.invoiceTotals.paid),
        "Total Balance": this.priceFormat(this.invoiceTotals.balance),
      };
      if (footer["Amount Credited"] === "$0.00") {
        delete footer["Amount Credited"];
      }
      if (footer["Redeemed Discount Value"] === "$0.00") {
        delete footer["Redeemed Discount Value"];
      }
      if (footer["Redeemed Points Value"] === "$0.00") {
        delete footer["Redeemed Points Value"];
      }
      return footer;
    },
    suggestedAmountFooter() {
      if (this.invoiceDetails.showSuggestedAnalysis === 0) {
        return {};
      }
      return {
        "Total Sugg": this.priceFormat(this.invoiceTotals.totalSuggested),
        "Sugg Credit": this.priceFormat(this.invoiceTotals.suggestedCredited),
        "Margin %": this.invoiceTotals.totalSuggestedProfit,
        "Sugg Balance": this.priceFormat(this.invoiceTotals.suggestedBalance),
      };
    },
    filters() {
      return this.$store.getters.filters;
    },
    productHistory() {
      return this.$store.getters.productHistory;
    },
    buttons() {
      return {
        Yes: {
          class: "btn-danger",
          function: () => this.deleteInvoiceItem(),
        },
        No: {
          class: "btn-light px-3",
          function: () => this.hideDeleteModal(),
        },
      };
    },
  },
  async created() {
    let filter = {
      resultsPerPage: this.$store.getters.settings["Results Per Page"],
    };
    await this.$store.dispatch("getFilters", filter);
  },
};
</script>

<style scoped></style>
