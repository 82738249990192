<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <form
            @submit.prevent="submit(updatedPriceGroupItem)"
            :class="{ 'disabled-form': disabled }"
          >
            <div class="p-2">
              <div class="mb-3">
                <label class="form-label" for="barcode">Barcode</label>
                <input
                  id="barcode"
                  name="barcode"
                  placeholder="Enter Barcode Name"
                  type="text"
                  class="form-control"
                  v-model="product.barcode"
                  disabled
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="productName">Product Name</label>
                <AutoCompleteInput
                  type="text"
                  placeholder="Choose a Product"
                  :chosen="{ value: updatedPriceGroupItem.productName }"
                  :options="products"
                  :disabled="disabled || disabledList.has('productName')"
                  @choice="setProductID"
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="costPrice">Cost Price</label>
                <input
                  id="costPrice"
                  name="costPrice"
                  placeholder="Enter Cost Price"
                  type="number"
                  step="0.01"
                  class="form-control"
                  v-model="product.costPrice"
                  disabled
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="sellingPrice">
                  Selling Price
                </label>
                <input
                  id="sellingPrice"
                  name="sellingPrice"
                  placeholder="Enter Selling Price"
                  type="number"
                  step="0.01"
                  class="form-control"
                  v-model="product.sellingPrice"
                  disabled
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="updatedPrice"
                  >Updated Price</label
                >
                <input
                  id="updatedPrice"
                  name="updatedPrice"
                  placeholder="Enter Updated Price"
                  type="number"
                  step="0.01"
                  class="form-control"
                  v-model="updatedPriceGroupItem.updatedPrice"
                  :disabled="disabled || disabledList.has('updatedPrice')"
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="suggestedRetailPrice">
                  Suggested Retail Price
                </label>
                <input
                  id="suggestedRetailPrice"
                  name="suggestedRetailPrice"
                  placeholder="Enter Suggested Retail Price"
                  type="number"
                  step="0.01"
                  class="form-control"
                  v-model="product.suggestedRetailPrice"
                  disabled
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="updatedSuggestedRetailPrice">
                  Updated Suggested Retail Price
                </label>
                <input
                  id="updatedSuggestedRetailPrice"
                  name="updatedSuggestedRetailPrice"
                  placeholder="Enter Updated Suggested Retail Price"
                  type="number"
                  step="0.01"
                  class="form-control"
                  v-model="updatedPriceGroupItem.updatedSuggestedRetailPrice"
                  :disabled="
                    disabled || disabledList.has('updatedSuggestedRetailPrice')
                  "
                />
              </div>

              <div class="hstack gap-2 mt-4">
                <button v-if="!disabled" type="submit" class="btn btn-primary">
                  Save
                </button>
                <button
                  v-if="disabled"
                  type="button"
                  class="btn btn-primary"
                  @click="edit"
                >
                  Edit
                </button>
                <button type="button" class="btn btn-light" @click="discard">
                  Back
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutoCompleteInput from "@/components/utils/AutoCompleteInput";

export default {
  name: "PriceGroupItemForm",
  components: { AutoCompleteInput },
  props: {
    priceGroupItem: {
      type: Object,
      required: true,
    },
    submitFunction: {
      type: Function,
      default: null,
    },
    disabledList: {
      type: Set,
      default: () => new Set(),
    },
  },
  data() {
    return {
      updatedPriceGroupItem: { ...this.priceGroupItem },
    };
  },
  methods: {
    submit() {
      if (!this.disabled) {
        delete this.updatedPriceGroupItem.value;
        this.updatedPriceGroupItem.price =
          this.updatedPriceGroupItem.updatedPrice;
        delete this.updatedPriceGroupItem.updatedPrice;
        this.updatedPriceGroupItem.suggestedRetailPrice =
          this.updatedPriceGroupItem.updatedSuggestedRetailPrice;
        delete this.updatedPriceGroupItem.updatedSuggestedRetailPrice;
        this.submitFunction(this.updatedPriceGroupItem);
      }
    },
    discard() {
      this.$router.push(
        `/priceGroups/${this.updatedPriceGroupItem.priceGroupID}`
      );
      this.updatedPriceGroupItem = { ...this.priceGroupItem };
    },
    edit() {
      this.$router.push("/priceGroups/edit/" + this.priceGroupItem.id);
    },
    async setProductID(value, id) {
      let filter = {
        resultsPerPage: this.$store.getters.settings["Results Per Page"],
        searchQuery: value,
      };
      await this.$store.dispatch("getProducts", filter);
      this.updatedPriceGroupItem.value = value;
      this.updatedPriceGroupItem.productID = id;
      if (id > 0) {
        await this.$store.dispatch("getProduct", id);
      }
    },
  },
  computed: {
    disabled() {
      return typeof this.submitFunction !== "function";
    },
    products() {
      return this.$store.getters.allProducts;
    },
    product() {
      return this.$store.getters.product;
    },
  },
  async created() {
    this.updatedPriceGroupItem.priceGroupID = this.$route.params.id;
    let filter = {
      resultsPerPage: this.$store.getters.settings["Results Per Page"],
    };
    await this.$store.dispatch("getProducts", filter);
  },
  async unmounted() {
    await this.$store.commit("reset");
  },
};
</script>
