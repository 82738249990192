<template>
  <dialogue-modal
    v-if="priceGroupToDelete"
    title="Are you sure you want to delete?"
    :message="priceGroupToDelete.name"
    :close-popup="hideDeleteModal"
    :buttons="buttons"
  />
  <page-title
    pageTitle="Customer Price Group"
    title="All Customer Price Groups"
  />

  <div class="row pb-4 gy-3">
    <div class="col-sm-4">
      <router-link to="/priceGroups/new" class="btn btn-primary addtax-modal">
        <i class="las la-plus me-1"></i> Add New
      </router-link>
    </div>

    <div class="col-sm-auto ms-auto">
      <div class="d-flex gap-3">
        <div class="search-box">
          <input
            type="text"
            class="form-control"
            id="searchMemberList"
            placeholder="Search for Result"
            v-model="searchQuery"
            @input="goToPage(1)"
          />
          <i class="las la-search search-icon"></i>
        </div>
      </div>
    </div>

    <div class="col-sm-auto">
      <div class="d-flex input-group">
        <label class="input-group-text" for="inputGroupSelect01">Show</label>
        <select
          class="form-select"
          id="resultsPerPage"
          v-model="resultsPerPage"
          @change="goToPage(1)"
        >
          <option
            v-for="option in resultsPerPageOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div v-if="data.length > 0" class="">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <table-component
              :headers="headers"
              :data="data"
              :actions="actions"
              :offset="(currentPage - 1) * resultsPerPage"
              @sort="goToPage"
            />
          </div>
        </div>
      </div>
    </div>
    <pagination-component
      :rows-loaded="data.length"
      :current-page="currentPage"
      :num-pages="numPages"
      :total-rows="totalRows"
      :go-to-page="goToPage"
    />
  </div>

  <div v-else class="row">
    <div class="col-xl-12">
      <div class="card">
        <LoadingModal v-if="loading" />
        <div v-else class="card-body">
          {{ error }}
        </div>
        <div class="gap-2 mt-4 card-body">
          <button
            type="button"
            class="btn btn-light"
            @click="this.$router.go(-1)"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import TableComponent from "@/components/utils/Table";
import LoadingModalComponent from "@/components/utils/LoadingModal";
import PaginationComponent from "@/components/utils/paginationComponent";
import DialogueModal from "@/components/utils/modals/DialogueModal";

export default {
  name: "AllPriceGroupsView",
  components: {
    DialogueModal,
    PaginationComponent,
    LoadingModal: LoadingModalComponent,
    PageTitle: PageTitleComponent,
    TableComponent: TableComponent,
  },
  data() {
    return {
      searchQuery: "",
      resultsPerPage: this.$store.getters.settings["Results Per Page"],
      priceGroupToDelete: null,
    };
  },
  computed: {
    headers() {
      return this.$store.getters.settings["Price Group Headers"];
    },
    data() {
      return this.$store.getters.allPriceGroups;
    },
    currentPage() {
      return this.$store.getters.priceGroupsCurrentPage;
    },
    numPages() {
      return this.$store.getters.priceGroupsNumPages;
    },
    totalRows() {
      return this.$store.getters.priceGroupsTotalRows;
    },
    resultsPerPageOptions() {
      return this.$store.getters.settings["Results Per Page Options"];
    },
    actions() {
      return {
        View: (priceGroup) => this.viewPriceGroup(priceGroup.id),
        Delete: (priceGroup) => this.showDeleteModal(priceGroup),
      };
    },
    error() {
      return this.$store.getters.priceGroupsError;
    },
    loading() {
      return this.$store.getters.priceGroupsLoading;
    },
    buttons() {
      return {
        Yes: {
          class: "btn-danger",
          function: () => this.deletePriceGroup(),
        },
        No: {
          class: "btn-light px-3",
          function: () => this.hideDeleteModal(),
        },
      };
    },
  },
  methods: {
    async goToPage(page, sortBy = null) {
      let filter = {
        sortingList: sortBy,
        currentPage: page,
        resultsPerPage:
          this.resultsPerPage === "ALL" ? this.totalRows : this.resultsPerPage,
        searchQuery: this.searchQuery,
      };
      await this.$store.dispatch("getPriceGroups", filter);
    },
    viewPriceGroup(id) {
      this.$router.push("/priceGroups/" + id);
    },
    editPriceGroup(id) {
      if (id > 1) {
        this.$router.push("/priceGroups/" + id);
      } else {
        //Notification Alert
        alert("You can't edit default price group");
      }
    },
    showDeleteModal(priceGroup) {
      this.priceGroupToDelete = priceGroup;
    },
    async deletePriceGroup() {
      if (this.priceGroupToDelete) {
        if (this.priceGroupToDelete.id > 1) {
          await this.$store.dispatch(
            "deletePriceGroup",
            this.priceGroupToDelete.id
          );
          await this.goToPage(1);
        } else {
          //Notification Alert
          alert("You can't delete default price group");
        }
        this.hideDeleteModal();
      } else {
        alert("There has been an error");
      }
    },
    hideDeleteModal() {
      this.priceGroupToDelete = null;
    },
  },
  async created() {
    let filter = {
      resultsPerPage: this.$store.getters.settings["Results Per Page"],
    };
    await this.$store.dispatch("getPriceGroups", filter);
  },
  beforeUnmount() {
    console.log("All PriceGroups Destroyed");
  },
};
</script>

<style scoped></style>
