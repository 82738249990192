import { createStore } from "vuex";
import user from "@/store/modules/user";
import priceGroups from "@/store/modules/priceGroups";
import productGroups from "@/store/modules/productGroups";
import billers from "@/store/modules/billers";
import buyers from "@/store/modules/buyers";
import invoices from "@/store/modules/invoices";
import products from "@/store/modules/products";
import reports from "@/store/modules/reports";
import barcodes from "@/store/modules/barcodes";
import payments from "@/store/modules/payments";
import expenses from "@/store/modules/expenses";
import settings from "@/store/modules/settings";

export default createStore({
  modules: {
    priceGroups,
    productGroups,
    billers,
    buyers,
    invoices,
    payments,
    expenses,
    products,
    barcodes,
    user,
    reports,
    settings,
  },
});
