<template>
  <div class="d-flex flex-column m-4">
    <h6 class="card-title mb-3 me-3">{{ settingName }}</h6>
    <ul class="list-unstyled">
      <li class="mb-2">
        <div class="input-group" style="max-width: 300px">
          <input
            type="text"
            class="form-control"
            style="max-width: 50px"
            value="No"
            disabled
          />
          <input type="text" class="form-control" value="Item" disabled />
          <button class="btn btn-warning" type="button" disabled>
            <i class="ri-information-line"></i>
          </button>
        </div>
      </li>
      <li v-for="(option, index) in settingValue" :key="index" class="mb-2">
        <div class="input-group" style="max-width: 300px">
          <input
            type="number"
            class="form-control"
            :value="index"
            style="max-width: 50px"
            @change="changeIndex($event.target.value, index)"
          />
          <input
            type="text"
            class="form-control"
            :value="option"
            @change="editItem($event.target.value, index)"
          />
          <button
            class="btn btn-danger"
            type="button"
            @click="removeItem(index)"
          >
            <i class="bi-trash3-fill align-bottom"></i>
          </button>
        </div>
      </li>
      <li class="mb-2">
        <div class="input-group" style="max-width: 300px">
          <input type="number" class="form-control" style="max-width: 50px" />
          <input type="text" class="form-control" />
          <button
            class="btn btn-primary"
            type="button"
            @click="addItem($event)"
          >
            <i class="las la-plus"></i>
          </button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "listSetting",
  props: {
    settingName: {
      type: String,
      required: true,
    },
    settingValue: {
      type: Array,
      required: true,
    },
  },
  methods: {
    async changeIndex(newIndex, currentIndex) {
      const parsedIndex = parseInt(newIndex, 10);
      if (
        isNaN(parsedIndex) ||
        parsedIndex < 0 ||
        parsedIndex >= this.settingValue.length
      ) {
        alert("Invalid index.");
        return;
      }
      const updatedValue = [...this.settingValue];
      const [movedItem] = updatedValue.splice(currentIndex, 1);
      updatedValue.splice(parsedIndex, 0, movedItem);
      const setting = {
        name: this.settingName,
        value: updatedValue,
      };
      await this.$store.dispatch("editSetting", setting);
      await this.$store.dispatch("getSettings");
    },
    async editItem(value, index) {
      const updatedSettingValue = [...this.settingValue];
      updatedSettingValue[index] = value.trim();
      const setting = {
        name: this.settingName,
        value: updatedSettingValue,
      };
      await this.$store.dispatch("editSetting", setting);
      await this.$store.dispatch("getSettings");
    },
    async addItem(event) {
      const inputs = event.target
        .closest(".input-group")
        .querySelectorAll("input");
      const indexInput = inputs[0].value;
      const itemInput = inputs[1].value.trim();
      inputs[0].value = "";
      inputs[1].value = "";
      const setting = {
        name: this.settingName,
        value: [...this.settingValue, itemInput],
      };
      await this.$store.dispatch("editSetting", setting);
      await this.$store.dispatch("getSettings");
      await this.changeIndex(indexInput, this.settingValue.length - 1);
      await this.$store.dispatch("getSettings");
    },
    async removeItem(index) {
      const setting = {
        name: this.settingName,
        value: this.settingValue.filter((_, i) => i !== index),
      };
      await this.$store.dispatch("editSetting", setting);
      await this.$store.dispatch("getSettings");
    },
  },
};
</script>

<style scoped></style>
