<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <form
            @submit.prevent="submit()"
            :class="{ 'disabled-form': disabled }"
          >
            <div class="p-2">
              <div class="mb-3">
                <label class="form-label" for="company">Company</label>
                <input
                  id="company"
                  name="company"
                  placeholder="Enter Company Name"
                  type="text"
                  class="form-control"
                  v-model="updatedBuyer.company"
                  :disabled="disabled"
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="name">Name</label>
                <input
                  id="name"
                  name="name"
                  placeholder="Enter Name"
                  type="text"
                  class="form-control"
                  v-model="updatedBuyer.name"
                  :disabled="disabled"
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="email">Email</label>
                <input
                  id="email"
                  name="email"
                  placeholder="Enter Email"
                  type="email"
                  class="form-control"
                  v-model="updatedBuyer.email"
                  :disabled="disabled"
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="phone">Phone</label>
                <input
                  id="phone"
                  name="phone"
                  placeholder="Enter Phone"
                  type="tel"
                  class="form-control"
                  v-model="updatedBuyer.phone"
                  :disabled="disabled"
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="address">Address</label>
                <input
                  id="address"
                  name="address"
                  placeholder="Enter Street Address"
                  type="text"
                  class="form-control"
                  v-model="updatedBuyer.address"
                  :disabled="disabled"
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="city">City</label>
                <input
                  id="city"
                  name="city"
                  placeholder="Enter City"
                  type="text"
                  class="form-control"
                  v-model="updatedBuyer.city"
                  :disabled="disabled"
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="state">State</label>
                <input
                  id="state"
                  name="state"
                  placeholder="Enter State"
                  type="text"
                  class="form-control"
                  v-model="updatedBuyer.state"
                  :disabled="disabled"
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="postalCode">Postal Code</label>
                <input
                  id="postalCode"
                  name="postalCode"
                  placeholder="Enter Postal Code"
                  type="text"
                  class="form-control"
                  v-model="updatedBuyer.postalCode"
                  :disabled="disabled"
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="priceGroup">Price Group</label>
                <AutoCompleteInput
                  type="text"
                  placeholder="Choose a Price Group"
                  :chosen="chosen"
                  :options="priceGroups"
                  :disabled="disabled"
                  @choice="setPriceGroupID"
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="awardPoints">
                  Award Points
                </label>
                <input
                  id="awardPoints"
                  name="awardPoints"
                  placeholder="Enter Award Points"
                  type="number"
                  class="form-control"
                  v-model="updatedBuyer.awardPoints"
                  :disabled="disabled"
                />
              </div>

              <div class="hstack gap-2 mt-4">
                <button v-if="!disabled" type="submit" class="btn btn-primary">
                  Save
                </button>
                <button
                  v-if="disabled"
                  type="button"
                  class="btn btn-primary"
                  @click="edit"
                >
                  Edit
                </button>
                <button type="button" class="btn btn-light" @click="discard">
                  Back
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutoCompleteInput from "@/components/utils/AutoCompleteInput";

export default {
  name: "BuyerForm",
  components: { AutoCompleteInput },
  props: {
    buyer: {
      type: Object,
      required: true,
    },
    submitFunction: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      updatedBuyer: { ...this.buyer },
    };
  },
  methods: {
    submit() {
      if (!this.disabled) {
        delete this.updatedBuyer.value;
        this.submitFunction(this.updatedBuyer);
      }
    },
    discard() {
      this.updatedBuyer = { ...this.buyer };
      this.$router.push("/customers/");
    },
    edit() {
      this.$router.push("/customers/edit/" + this.buyer.id);
    },
    async setPriceGroupID(value, id) {
      let filter = {
        resultsPerPage: this.$store.getters.settings["Results Per Page"],
        searchQuery: value,
      };
      await this.$store.dispatch("getPriceGroups", filter);
      this.updatedBuyer.value = value;
      this.updatedBuyer.priceGroupID = id;
    },
  },
  computed: {
    priceGroups() {
      return this.$store.getters.allPriceGroups;
    },
    error() {
      return this.$store.getters.priceGroupsError;
    },
    disabled() {
      return typeof this.submitFunction !== "function";
    },
    chosen() {
      return {
        id: this.updatedBuyer.priceGroupID,
        value: this.updatedBuyer.priceGroup,
      };
    },
  },
  async created() {
    let filter = {
      resultsPerPage: this.$store.getters.settings["Results Per Page"],
    };
    await this.$store.dispatch("getPriceGroups", filter);
  },
};
</script>
