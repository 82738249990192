<template>
  <div class="d-flex flex-column m-4">
    <h6 class="card-title mb-3 me-3">{{ settingName }}</h6>
    <div class="input-group" style="max-width: 300px">
      <input
        type="text"
        class="form-control"
        :value="settingValue"
        @input="editSettings($event.target.value)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "stringSetting",
  props: {
    settingName: {
      type: String,
      required: true,
    },
    settingValue: {
      type: String,
      required: true,
    },
  },
  methods: {
    async editSettings(value) {
      const setting = {
        name: this.settingName,
        value: value,
      };
      await this.$store.dispatch("editSetting", setting);
      await this.$store.dispatch("getSettings");
    },
  },
};
</script>

<style scoped></style>
