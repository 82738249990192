import axios from "axios";
import { CONFIG } from "@/constants/config.js";

const settingBackend = CONFIG.BACKEND_URL + "settings";

const state = {
  uniqueHeaders: [],
  headerObject: {},
  settings: {},
  navLinks: {},
};

const config = () => ({
  headers: {
    Token: localStorage.getItem("accessToken"),
  },
});

const getters = {
  uniqueHeaders: (state) => state.uniqueHeaders,
  headerObject: (state) => state.headerObject,
  settings: (state) => state.settings,
  navLinks: (state) => state.navLinks,
};

const actions = {
  async addSetting({ commit }, setting) {
    commit("setLoading", true);
    await axios
      .post(`${settingBackend}/add/`, setting, config())
      .then()
      .catch(async (error) => {
        await commit("reset");
        console.log(error);
      });
  },
  async getSettings({ commit /*, dispatch */ }, filter = null) {
    await axios
      .post(`${settingBackend}/`, filter, config())
      .then(async (response) => {
        await commit("setSettings", response.data);
      })
      .catch(async (error) => {
        await commit("reset");
        console.log(error);
      });
  },
  async editSetting({ commit }, setting) {
    commit("setLoading", true);
    await axios
      .patch(`${settingBackend}/`, setting, config())
      .then()
      .catch(async (error) => {
        await commit("reset");
        console.log(error);
      });
  },
};

const mutations = {
  setSettings(state, data) {
    state.uniqueHeaders = [];
    state.uniqueHeaders = [...new Set(data.map((item) => item.header))];

    state.headerObject = {};
    data.forEach((item) => {
      const { header, name, type, value } = item;
      if (!state.headerObject[header]) {
        state.headerObject[header] = [];
      }
      state.headerObject[header].push({ name, type, value });
    });

    state.settings = {};
    data.forEach((item) => {
      const { name, value, type } = item;
      let parsedValue = value;
      if (type === "dict" && Array.isArray(value)) {
        parsedValue = value.reduce((acc, curr) => {
          if (curr.key && curr.value) {
            acc[curr.key] = curr.value;
          }
          return acc;
        }, {});
      }
      state.settings[name] = parsedValue;
    });

    state.navLinks = {};
    const headerObject = state.headerObject["Navbar Settings"];
    state.navLinks = Object.values(headerObject).reduce((links, pageGroup) => {
      links[pageGroup.name] = pageGroup.value.reduce((pages, page) => {
        pages[page.key] = page.value;
        return pages;
      }, {});
      return links;
    }, {});
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
