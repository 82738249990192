<template>
  <add-payment-modal
    v-if="showAddPaymentModal"
    :invoice-summary="invoiceDetails"
    :close-popup="hidePaymentModal"
  />
  <dialogue-modal
    v-if="showDelete"
    title="Are you sure you want to delete?"
    :message="invoiceDetails.invoiceNo"
    :close-popup="hideDeleteModal"
    :buttons="buttons"
  />
  <fix-buyer-modal
    v-if="
      $store.getters.settings['Show Fix Customer Popup'] &&
      this.invoiceDetails.customerID &&
      (!this.invoiceDetails.customerEmail ||
        !this.invoiceDetails.customerPhone ||
        !this.invoiceDetails.fullCustomerAddress)
    "
    :invoice-summary="invoiceDetails"
    :close-popup="hideFixBuyerModal"
  />
  <page-title pageTitle="Invoice" :title="invoiceDetails.invoiceNo" />

  <loading-modal v-if="loading" />
  <div v-else class="row justify-content-center">
    <div class="col-xxl-9">
      <div class="card">
        <form id="invoice_form">
          <invoice-details-input-group />
          <div class="row pt-2 pb-4 border-bottom border-bottom-dashed">
            <div
              class="col hstack gap-2 justify-content-center px-5 mx-sm-2 mx-md-5"
            >
              <button
                type="button"
                @click="showPaymentModal()"
                class="btn btn-primary"
              >
                <i class="las la-plus me-1"></i>
                Add Payment
              </button>
              <router-link :to="`/payment/invoice/${id}`" class="btn btn-info">
                <i class="ri-eye-line align-bottom me-1" />
                View Payments
              </router-link>
            </div>
          </div>
          <div class="card-body p-4" bis_skin_checked="1">
            <invoice-items-table-input />
            <div class="mt-4" bis_skin_checked="1">
              <label
                for="exampleFormControlTextarea1"
                class="form-label text-muted text-uppercase fw-semibold"
              >
                Remarks
              </label>
              <textarea
                class="form-control alert alert-success"
                id="exampleFormControlTextarea1"
                v-model="invoiceDetails.remarks"
                @blur="updateInvoice('remarks')"
                placeholder="Remarks"
                rows="2"
                spellcheck="false"
              />
            </div>

            <div class="mt-4" bis_skin_checked="1">
              <label
                for="exampleFormControlTextarea1"
                class="form-label text-muted text-uppercase fw-semibold"
              >
                Notes For Customer
              </label>
              <textarea
                class="form-control alert alert-info"
                id="exampleFormControlTextarea1"
                v-model="invoiceDetails.notes"
                @blur="updateInvoice('notes')"
                placeholder="Notes For Customer"
                rows="2"
                spellcheck="false"
              />
            </div>
            <div
              class="hstack gap-2 justify-content-end d-print-none mt-4"
              bis_skin_checked="1"
            >
              <router-link :to="`/invoices/`" class="btn btn-light">
                Back
              </router-link>
              <router-link :to="`/invoices/${id}`" class="btn btn-info">
                <i class="ri-eye-line align-bottom me-1" />
                Submit
              </router-link>
              <button
                type="button"
                @click="showDeleteModal()"
                class="btn btn-danger"
              >
                <i class="bi-trash3-fill align-bottom me-1" />
                Delete
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import InvoiceItemsTableInput from "@/components/invoice/inputGroups/InvoiceItemsTableInput";
import LoadingModal from "@/components/utils/LoadingModal";
import InvoiceDetailsInputGroup from "@/components/invoice/inputGroups/InvoiceDetailsInputGroup";
import DialogueModal from "@/components/utils/modals/DialogueModal";
import AddPaymentModal from "@/components/payment/AddPaymentModal";
import FixBuyerModal from "@/components/buyer/FixBuyerModal";

export default {
  name: "EditInvoiceView",
  components: {
    FixBuyerModal,
    AddPaymentModal,
    DialogueModal,
    InvoiceDetailsInputGroup,
    LoadingModal,
    PageTitle: PageTitleComponent,
    InvoiceItemsTableInput,
  },
  data() {
    return {
      id: -1,
      showDelete: false,
      showAddPaymentModal: false,
    };
  },
  computed: {
    data() {
      return this.$store.getters.invoice;
    },
    invoiceDetails() {
      return this.$store.getters.invoiceSummary;
    },
    loading() {
      console.log(this.invoiceDetails);
      return !this.invoiceDetails.id;
    },
    buttons() {
      return {
        Yes: {
          class: "btn-danger",
          function: () => this.deleteInvoice(),
        },
        No: {
          class: "btn-light px-3",
          function: () => this.hideDeleteModal(),
        },
      };
    },
  },
  methods: {
    async updateInvoice(key) {
      await this.$store.dispatch("editInvoice", {
        id: this.invoiceDetails.id,
        [key]: this.invoiceDetails[key],
      });
    },
    edit() {
      this.$router.push("/invoices/edit/" + this.invoiceDetails.id);
    },
    showPaymentModal() {
      this.showAddPaymentModal = true;
    },
    hidePaymentModal() {
      this.showAddPaymentModal = false;
    },
    showDeleteModal() {
      this.showDelete = true;
    },
    async deleteInvoice() {
      await this.$store.dispatch("deleteInvoice", this.invoiceDetails.id);
      this.hideDeleteModal();
      this.$router.push("/Invoices");
    },
    hideDeleteModal() {
      this.showDelete = false;
    },
    hideFixBuyerModal() {
      this.showFixBuyerModal = false;
    },
  },
  async created() {
    this.id = parseInt(this.$route.params.id);
    await this.$store.dispatch("getInvoice", this.id);
    await this.$store.dispatch("getInvoiceItems", {
      id: this.id,
      filter: null,
    });
    await this.$store.dispatch("getBillers");
  },
  async beforeUnmount() {
    await this.$store.dispatch("resetInvoice");
    await this.$store.dispatch("resetBiller");
    await this.$store.dispatch("resetCustomer");
  },
};
</script>

<style scoped></style>
