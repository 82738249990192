<template>
  <page-title-component pageTitle="Settings" title="Settings" />
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div
          class="card-header d-flex justify-content-between align-items-center"
          @click="collapse(-1)"
        >
          <h6 class="card-title mb-0">Add Setting</h6>
          <i
            class="ri-arrow-up-s-line mx-2 fs-3"
            v-if="collapsedSettings.includes(-1)"
          ></i>
          <i
            class="ri-arrow-down-s-line mx-2 fs-3"
            v-if="!collapsedSettings.includes(-1)"
          ></i>
        </div>

        <!-- Card Body -->
        <div class="card-body" v-if="collapsedSettings.includes(-1)">
          <ul class="list-unstyled">
            <!-- Header Row -->
            <li class="mb-2">
              <div class="row g-2 align-items-center">
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    value="Header"
                    disabled
                  />
                </div>
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    value="Name"
                    disabled
                  />
                </div>
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    value="Type"
                    disabled
                  />
                </div>
                <div class="col-auto">
                  <button class="btn btn-warning" type="button" disabled>
                    <i class="ri-information-line"></i>
                  </button>
                </div>
              </div>
            </li>

            <!-- Input Row -->
            <li>
              <div class="row g-2 align-items-center">
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Header"
                  />
                </div>
                <div class="col">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Name"
                  />
                </div>
                <div class="col">
                  <select class="form-select" name="settingTypes">
                    <option value="string">String</option>
                    <option value="int">Integer</option>
                    <option value="boolean">Boolean</option>
                    <option value="list">List</option>
                    <option value="dict">Dictionary</option>
                  </select>
                </div>
                <div class="col-auto">
                  <button
                    class="btn btn-primary"
                    type="button"
                    @click="addItem($event)"
                  >
                    <i class="las la-plus"></i>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="card" v-for="(header, index) in uniqueHeaders" :key="header">
        <div
          class="card-header d-flex justify-content-between align-items-center"
          @click="collapse(index)"
        >
          <h6 class="card-title mb-0">{{ header }}</h6>
          <i
            class="ri-arrow-up-s-line mx-2 fs-3"
            v-if="collapsedSettings.includes(index)"
          ></i>
          <i
            class="ri-arrow-down-s-line mx-2 fs-3"
            v-if="!collapsedSettings.includes(index)"
          ></i>
        </div>
        <div class="card-body" v-if="collapsedSettings.includes(index)">
          <template v-for="setting in headerObject[header]" :key="setting.name">
            <switch-setting
              v-if="setting.type === 'boolean'"
              :setting-name="setting.name"
              :setting-value="setting.value"
            />
            <numeric-setting
              v-else-if="setting.type === 'int'"
              :setting-name="setting.name"
              :setting-value="setting.value"
            />
            <string-setting
              v-else-if="setting.type === 'string'"
              :setting-name="setting.name"
              :setting-value="setting.value"
            />
            <list-setting
              v-else-if="setting.type === 'list'"
              :setting-name="setting.name"
              :setting-value="setting.value"
            />
            <dict-setting
              v-else-if="setting.type === 'dict'"
              :setting-name="setting.name"
              :setting-value="setting.value"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import SwitchSetting from "@/components/settings/switchSetting";
import NumericSetting from "@/components/settings/numericSetting";
import ListSetting from "@/components/settings/listSetting";
import StringSetting from "@/components/settings/stringSetting";
import DictSetting from "@/components/settings/dictSetting";

export default {
  name: "SettingsView",
  components: {
    DictSetting,
    StringSetting,
    ListSetting,
    NumericSetting,
    SwitchSetting,
    PageTitleComponent,
  },
  data() {
    return {
      collapsedSettings: [],
    };
  },
  computed: {
    uniqueHeaders() {
      return this.$store.getters.uniqueHeaders;
    },
    headerObject() {
      return this.$store.getters.headerObject;
    },
    settings() {
      return this.$store.getters.settings;
    },
  },
  methods: {
    collapse(i) {
      const index = this.collapsedSettings.indexOf(i);
      if (index === -1) {
        // Add the index if it doesn't exist
        this.collapsedSettings.push(i);
      } else {
        // Remove the specific element from the array
        this.collapsedSettings.splice(index, 1); // Remove 1 item at 'index'
      }
    },
    async addItem(event) {
      // Locate the closest `.row` to retrieve inputs and the select element
      const row = event.target.closest(".row");

      // Get all inputs and the select element within the row
      const inputs = row.querySelectorAll("input");
      const select = row.querySelector("select");

      // Extract values from the inputs and select element
      const headerInput = inputs[0].value.trim(); // Header
      const nameInput = inputs[1].value.trim(); // Name
      const typeInput = select.value; // Type

      // Validate inputs
      if (!headerInput || !nameInput || !typeInput) {
        alert("All fields must be filled out.");
        return;
      }

      // Clear the input and select fields
      inputs[0].value = "";
      inputs[1].value = "";
      select.value = "string"; // Reset select to default

      let value;
      switch (typeInput) {
        case "string":
          value = "";
          break;
        case "int":
          value = 0;
          break;
        case "boolean":
          value = false;
          break;
        case "dict":
        case "list":
          value = [];
          break;
        default:
          value = null;
          break;
      }

      // Construct the setting object
      const setting = {
        header: headerInput,
        name: nameInput,
        value: value, // Value is set to null initially
        type: typeInput,
      };

      // Dispatch the new setting to the Vuex store
      await this.$store.dispatch("addSetting", setting);
      // Refresh the settings list
      await this.$store.dispatch("getSettings");

      this.collapsedSettings.push(this.uniqueHeaders.length - 1);
      console.log(this.collapsedSettings);
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    },
  },
  async created() {
    await this.$store.dispatch("getSettings");
  },
};
</script>

<style scoped></style>
