<template>
  <div class="d-flex justify-content-start align-items-center m-4">
    <h6 class="card-title mb-0 me-3">{{ settingName }}</h6>
    <div class="input-step">
      <button type="button" class="minus" @click="changeSettings(-1)">–</button>
      <input
        type="number"
        class="product-quantity"
        min="0"
        max="100"
        :value="settingValue"
        @input="editSettings($event.target.value)"
      />
      <button type="button" class="plus" @click="changeSettings(1)">+</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "numericSetting",
  props: {
    settingName: {
      type: String,
      required: true,
    },
    settingValue: {
      type: Number,
      required: true,
    },
  },
  methods: {
    async editSettings(value) {
      const setting = {
        name: this.settingName,
        value: parseInt(value),
      };
      await this.$store.dispatch("editSetting", setting);
      await this.$store.dispatch("getSettings");
    },
    async changeSettings(change) {
      const setting = {
        name: this.settingName,
        value: this.settingValue + change,
      };
      await this.$store.dispatch("editSetting", setting);
      await this.$store.dispatch("getSettings");
    },
  },
};
</script>

<style scoped></style>
