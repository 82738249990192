<template>
  <dialogue-modal
    v-if="productGroupItemToDelete"
    title="Are you sure you want to delete?"
    :message="productGroupItemToDelete.productName"
    :close-popup="hideDeleteModal"
    :buttons="buttons"
  />
  <page-title pageTitle="Product Price Group" :title="productGroupName" />

  <div class="row pb-4 gy-3">
    <div class="col-sm-4">
      <router-link
        :to="`/productGroups/${id}/add`"
        class="btn btn-primary addtax-modal"
      >
        <i class="las la-plus me-1"></i> Add New
      </router-link>
    </div>

    <div class="col-sm-auto ms-auto">
      <div class="d-flex gap-3">
        <div class="search-box">
          <input
            type="text"
            class="form-control"
            id="searchMemberList"
            placeholder="Search for Result"
            v-model="searchQuery"
            @input="goToPage(1)"
          />
          <i class="las la-search search-icon"></i>
        </div>
      </div>
    </div>

    <div class="col-sm-auto">
      <div class="d-flex input-group">
        <label class="input-group-text" for="inputGroupSelect01">Show</label>
        <select
          class="form-select"
          id="resultsPerPage"
          v-model="resultsPerPage"
          @change="goToPage(1)"
        >
          <option
            v-for="option in resultsPerPageOptions"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div v-if="data.length > 0" class="">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <table-component
              :headers="headers"
              :data="formattedData"
              :actions="actions"
              :offset="(currentPage - 1) * resultsPerPage"
              @sort="goToPage"
            />
          </div>
        </div>
      </div>
    </div>
    <pagination-component
      :rows-loaded="data.length"
      :current-page="currentPage"
      :num-pages="numPages"
      :total-rows="totalRows"
      :go-to-page="goToPage"
    />
  </div>

  <div v-else class="row">
    <div class="col-xl-12">
      <div class="card">
        <LoadingModal v-if="loading" />
        <div v-else class="card-body">
          {{ error }}
        </div>
        <div class="gap-2 mt-4 card-body">
          <button
            type="button"
            class="btn btn-light"
            @click="this.$router.go(-1)"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/shared/PageTitle";
import TableComponent from "@/components/utils/Table";
import LoadingModalComponent from "@/components/utils/LoadingModal";
import PaginationComponent from "@/components/utils/paginationComponent";
import DialogueModal from "@/components/utils/modals/DialogueModal";

export default {
  name: "AllProductGroupsView",
  components: {
    DialogueModal,
    PaginationComponent,
    LoadingModal: LoadingModalComponent,
    PageTitle: PageTitleComponent,
    TableComponent: TableComponent,
  },
  data() {
    return {
      id: -1,
      searchQuery: "",
      resultsPerPage: this.$store.getters.settings["Results Per Page"],
      productGroupItemToDelete: null,
    };
  },
  computed: {
    headers() {
      return this.$store.getters.settings["Product Group Item Headers"];
    },
    formattedData() {
      const allProductGroupItems = this.$store.getters.productGroup;
      return allProductGroupItems.map((productGroupItem) => ({
        ...productGroupItem,
        costPrice: this.priceFormat(productGroupItem.costPrice),
        sellingPrice: this.priceFormat(productGroupItem.sellingPrice),
        suggestedRetailPrice: this.priceFormat(
          productGroupItem.suggestedRetailPrice
        ),
      }));
    },
    data() {
      return this.$store.getters.productGroup;
    },
    productGroupName() {
      if (this.$store.getters.productGroupSummary.name) {
        return this.$store.getters.productGroupSummary.name;
      }
      return "Price Group";
    },
    currentPage() {
      return this.$store.getters.productGroupsCurrentPage;
    },
    numPages() {
      return this.$store.getters.productGroupsNumPages;
    },
    totalRows() {
      return this.$store.getters.productGroupsTotalRows;
    },
    resultsPerPageOptions() {
      return this.$store.getters.settings["Results Per Page Options"];
    },
    actions() {
      return {
        Delete: (productGroupItem) => this.showDeleteModal(productGroupItem),
      };
    },
    error() {
      return this.$store.getters.productGroupsError;
    },
    loading() {
      return this.$store.getters.productGroupsLoading;
    },
    buttons() {
      return {
        Yes: {
          class: "btn-danger",
          function: () => this.deleteProductGroupItem(),
        },
        No: {
          class: "btn-light px-3",
          function: () => this.hideDeleteModal(),
        },
      };
    },
  },
  methods: {
    priceFormat(price) {
      return `$${price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`.replace("$-", "-$");
    },
    async goToPage(page, sortBy = null) {
      let filter = {
        sortingList: sortBy,
        currentPage: page,
        resultsPerPage:
          this.resultsPerPage === "ALL" ? this.totalRows : this.resultsPerPage,
        searchQuery: this.searchQuery,
      };
      await this.$store.dispatch("getProductGroupItems", {
        id: this.id,
        filter,
      });
    },
    showDeleteModal(productGroupItem) {
      this.productGroupItemToDelete = productGroupItem;
    },
    async deleteProductGroupItem() {
      if (this.productGroupItemToDelete) {
        await this.$store.dispatch(
          "deleteProductGroupItem",
          this.productGroupItemToDelete.id
        );
        await this.goToPage(1);
        this.hideDeleteModal();
      } else {
        alert("There has been an error");
      }
    },
    hideDeleteModal() {
      this.productGroupItemToDelete = null;
    },
  },
  async created() {
    this.id = parseInt(this.$route.params.id);
    await this.$store.dispatch("getProductGroup", this.id);
    let filter = {
      resultsPerPage: this.$store.getters.settings["Results Per Page"],
    };
    await this.$store.dispatch("getProductGroupItems", { id: this.id, filter });
  },
  beforeUnmount() {
    console.log("All Product Groups Destroyed");
  },
};
</script>

<style scoped></style>
