<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <form
            @submit.prevent="submit()"
            :class="{ 'disabled-form': disabled }"
          >
            <div class="p-2">
              <div class="mb-3">
                <label class="form-label" for="date">Date</label>
                <DateInput
                  id="date"
                  name="date"
                  placeholder="Enter Date"
                  type="text"
                  class="form-control"
                  v-model="updatedExpense.date"
                  :disabled="disabled"
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="company">Biller</label>
                <select
                  id="company"
                  name="company"
                  placeholder="Choose a Biller"
                  class="form-control"
                  v-model="updatedExpense.billerID"
                  :disabled="disabled"
                >
                  <option
                    v-for="biller in billers"
                    :value="biller.id"
                    :key="biller.id"
                  >
                    {{ biller.company }}
                  </option>
                </select>
              </div>

              <div class="mb-3">
                <label class="form-label" for="expense">Expense</label>
                <input
                  id="expense"
                  name="expense"
                  placeholder="Enter Expense Name"
                  type="text"
                  class="form-control"
                  v-model="updatedExpense.expense"
                  :disabled="disabled"
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="category">Category</label>
                <input
                  id="category"
                  name="category"
                  placeholder="Enter Category"
                  type="category"
                  class="form-control"
                  v-model="updatedExpense.category"
                  :disabled="disabled"
                />
              </div>

              <div class="mb-3">
                <label class="form-label" for="amount"> Expense Amount </label>
                <input
                  id="amount"
                  placeholder="Enter Expense Amount"
                  v-model="updatedExpense.amount"
                  type="text"
                  class="form-control"
                  :disabled="disabled"
                />
              </div>

              <div class="hstack gap-2 mt-4">
                <button v-if="!disabled" type="submit" class="btn btn-primary">
                  Save
                </button>
                <button
                  v-if="disabled"
                  type="button"
                  class="btn btn-primary"
                  @click="edit"
                >
                  Edit
                </button>
                <button type="button" class="btn btn-light" @click="discard">
                  Back
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateInput from "@/components/utils/DateInput";

export default {
  name: "ExpensesForm",
  components: { DateInput },
  props: {
    expense: {
      type: Object,
      required: true,
    },
    submitFunction: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      updatedExpense: { ...this.expense },
    };
  },
  methods: {
    submit() {
      if (!this.disabled) {
        this.submitFunction(this.updatedExpense);
      }
    },
    discard() {
      this.updatedExpense = { ...this.expense };
      this.$router.push("/expenses/");
    },
    edit() {
      this.$router.push("/expenses/edit/" + this.expense.id);
    },
  },
  computed: {
    billers() {
      return this.$store.getters.allBillers;
    },
    error() {
      return this.$store.getters.billersError;
    },
    disabled() {
      return typeof this.submitFunction !== "function";
    },
  },
  async created() {
    let filter = {
      resultsPerPage: this.$store.getters.settings["Results Per Page"],
    };
    await this.$store.dispatch("getBillers", filter);
    this.updatedExpense.billerID = this.updatedExpense.billerID
      ? this.updatedExpense.billerID
      : 1;
    let currentDate = new Date();
    let startDate = new Date(currentDate.setMonth(currentDate.getMonth()));
    startDate = `${startDate.getFullYear()}-${(
      startDate.getMonth() +
      1 +
      ""
    ).padStart(2, "0")}-${(startDate.getDate() + "").padStart(2, "0")}`;
    this.updatedExpense.date = this.updatedExpense.date
      ? this.updatedExpense.date
      : startDate;
  },
};
</script>
