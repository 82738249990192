<template>
  <sidebar-component :menuItems="menuItems" />
</template>

<script>
import SidebarComponent from "@/components/utils/Sidebar";
export default {
  name: "MenuComponent",
  components: { SidebarComponent },
  computed: {
    menuItems() {
      return this.$store.getters.navLinks;
    },
  },
};
</script>

<style scoped></style>
